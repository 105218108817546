import _clamp from 'lodash/clamp';
import throwInServerSideRendering from '@dbh/throw-in-server-side-rendering';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import { isServerSideRendering } from '@dbh/environment';
import PropTypes from 'prop-types';
import '@dbh/generic-types';
import _upperFirst from 'lodash/upperFirst';
import nanoMemoize from 'nano-memoize';
import Sentry from '@dbh/sentry';
import _noop from 'lodash/noop';
import { validateFunction, validateString } from '@dbh/validation';

/**
 * Returns the window vertical scroll position.
 * @return {number} .
 */const getWindowScrollY=()=>isServerSideRendering()?0:window.scrollY||window.pageYOffset;var getWindowScrollY$1 = getWindowScrollY;

/**
 * @typedef {Array<Promise, Function>} AnimatePromiseAndCancel Containes a
 * `Promise` that resolves when the animation completes, and a method to cancel
 * the animation.
 *//**
 * @typedef {Object} SmoothScrollToOptions
 * @property {string} domElementId .
 * @property {HTMLElement} domElement .
 * @property {number} navbarOffset .
 * @property {number} assumeScrollFinishedAfterTimeout .
 */const smoothScrollToOptionsPropType=_noop;const windowScreenPropType=_noop;const createDocumentVisibilityChangeWatcherCallbackParamPropType=_noop;

/**
 * @typedef {import('./types').SmoothScrollToOptions} SmoothScrollToOptions
 *//**
 * Calls `window.scrollTo` with the "smooth" scrolling enabled (we polyfill it,.
 * @see `@dbh/browser-polyfills`). Only `await` this function if you need to do
 * something or call something after the scroll is supposedly finished (the
 * function calculates an estimated "scroll completion" time, it doesn't actually
 * check the `DOM` or listen to scroll changes).
 * @param {SmoothScrollToOptions} options .
 * @return {Promise} Only returns after a certain timeout, when the scroll is
 * supposedly finished.
 */const smoothScrollTo=withConformsTo("smoothScrollTo",[],async a=>{const{domElement:b,domElementId:c,navbarOffset:d,assumeScrollFinishedAfterTimeout:e}=a,f=b||document.getElementById(c),g=getWindowScrollY$1(),{top:h}=f.getBoundingClientRect(),i=h+g-d;// @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect}
// @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Window/pageYOffset}
window.scrollTo({top:i,behavior:"smooth"// @see `@dbh/browser-polyfill`.
});// Defer callback invocation to improve the `smooth-scroll` performance.
const j=Math.abs(g-i),k=Math.ceil(j);let l=e;if([null,void 0].includes(l)){// We "assume" (otherwise we would have to use an intersection observer on
// the target element, to do something more sophisticated) that the scroll
// will be "finished" after the following timeout. We make it proportionate
// to the scroll distance in pixel; this is quite arbitrary, and we can't
// predict the speed at which a device, maybe older,may scroll. It's just
// an estimate.
l=_clamp(k,50,350);}await new Promise(a=>setTimeout(a,l));});var smoothScrollTo$1 = smoothScrollTo;

const fnName="injectScript",injectScript=withConformsTo("injectScript",[],(a,b)=>new Promise((c,d)=>{throwInServerSideRendering(fnName);const e=document.createElement("SCRIPT");e.src=a,e.type="text/javascript",b&&Object.keys(b).forEach(a=>{e.setAttribute(a,b[a]);}),e.onerror=d,e.onload=c,document.head.appendChild(e);}));/**
 * Injects a script dynamically and asynchronously into the `DOM`.
 * @param {string} scriptUrl The `URL` of the script to inject.
 * @param {Object?} attributes Options to adapt the `DOM` element, before it is injected.
 * @param {boolean?} attributes.defer @see {@link https://www.w3schools.com/tags/att_script_defer.asp}.
 * @param {boolean?} attributes.async @see {@link https://www.w3schools.com/tags/att_script_async.asp}.
 * @param {(string|number)?} attributes.id @see {@link https://www.w3schools.com/tags/att_id.asp}.
 */var injectScript$1 = injectScript;

/**
 * Returns the `JSON` data contained in an `HTML` element (usually a `script`)
 * with a certain `id` attribute.
 * @example
 * // <script id="lorem">{"ipsum": true}</script>
 * getSerializedDataFromDom('lorem'); // Returns `{ipsum: true}`.
 * @param {string} domId .
 * @return {Object} The parsed data.
 */const getSerializedDataFromDom=withConformsTo("getSerializedDataFromDom",["domId",PropTypes.string],a=>{const b=document.getElementById(a);if(!b)throw new Error("(`getSerializedDataFromDom`) I could not find the `script[type=\"application/json\"]` with ID: `"+a+"`. It should contain a serialized `json` object.");// `<el>.textContent` is faster than `<el>.innerHTML`.
// @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Node/textContent#Differences_from_innerHTML}
return JSON.parse(b.textContent)});var getSerializedDataFromDom$1 = getSerializedDataFromDom;

const BROWSER_VENDOR_PREFIXES=["webkit","moz","ms","o"],getPrefixedPropName=nanoMemoize(withConformsTo("getPrefixedPropName",[],a=>{// If `propName` is natively supported, just return it.
if(a in document)return a;const b=_upperFirst(a);// Loop over all the known vendor prefixes. until we maybe find the
// prefixed property. @example `webkitVisibilityState` in `Android Browser`.
for(let c=0;c<BROWSER_VENDOR_PREFIXES.length;c+=1){const a=""+BROWSER_VENDOR_PREFIXES[c]+b;if(a in document)return a}// Unfortunately `propName` is not supported in the current browser.
}));/**
 * Get the name of the `propName` property of the `document` of the current
 * browser, if it is supported: also check if it is prefixed (@example the
 * `visibilityState` property is vendor prefixed in `Android Browser`).
 * @param {string} propName The name of the property.
 * @return {string?} The "actual" name of the property in the `document` of the
 * current browser, if supported.
 * @see {@link https://www.html5rocks.com/en/tutorials/pagevisibility/intro/}
 */var getPrefixedPropName$1 = getPrefixedPropName;

/**
 * Is the current document "hidden"? This is particularly relevant in mobile
 * browsers. "As you might expect, the hidden attribute returns `true` when the
 * document is not visible at all. Typically, this means that the document is
 * either minimized, on a background tab, the OS's lock screen is up, etc.
 * The attribute is set to false if any part of the document is at least
 * partially visible on at least one display. In addition, to accommodate
 * accessibility tools, the hidden attribute can be set to false when a tool
 * such as a screen magnifier completely obscures the document, but is showing
 * a view of it".
 * @return {boolean} Is the current document "hidden".
 * @see {@link https://www.html5rocks.com/en/tutorials/pagevisibility/intro/}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilityState}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API}
 * @see {@link https://www.w3.org/TR/page-visibility/}
 */const isDocumentHidden=()=>{throwInServerSideRendering("isDocumentHidden");const a=getPrefixedPropName$1("hidden");return !!a&&document[a]};var isDocumentHidden$1 = isDocumentHidden;

/**
 * Get the `document` visibility state; one of: `DOCUMENT_VISIBILITY_STATES`.
 * @return {string} The current document visibility state.
 * @see {@link https://www.html5rocks.com/en/tutorials/pagevisibility/intro/}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilityState}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API}
 * @see {@link https://www.w3.org/TR/page-visibility/}
 */const getDocumentVisibilityState=()=>{const a=getPrefixedPropName$1("visibilityState");return a?document[a]:void 0};var getDocumentVisibilityState$1 = getDocumentVisibilityState;

/*
 *
 * `easeInOutSin`: `@dbh/dom`.
 *
 */const easeInOutSin=a=>(1+Math.sin(Math.PI*a-Math.PI/2))/2;var easeInOutSin$1 = easeInOutSin;

Object.freeze({HIDDEN:"hidden",VISIBLE:"visible",PRERENDER:"prerender",UNLOADED:"unloaded"});const DEFAULT_ANIMATE_OPTIONS={ease:easeInOutSin$1,duration:300};const DEFAULT_ANIMATE_PAYLOAD=Object.freeze({cancelled:!1,duration:0});const LEGACY_PAGE_VISIBILITY_EVENT_NAMES=["pagehide","beforeunload","unload"];

/**
 * Creates a block of code that subscribes, if supported, to the `DOM`
 * visibility change event, and calls the provided callback function.
 * @param {Function} fnHandleVisibilityChange It will be called when a `document`
 * visibility change `DOM` event is fired by the browser.
 * @param {boolean?} listenToLegacyEvents .
 * @return {{destroy: Function}} `destroy` Removes the internal `DOM` event
 * listener that this code added.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/Events/visibilitychange}
 */const createDocumentVisibilityChangeWatcher=withConformsTo("createDocumentVisibilityChangeWatcher",[],(a,b)=>{throwInServerSideRendering("createDocumentVisibilityChangeWatcher");let c=getDocumentVisibilityState$1(),d=isDocumentHidden$1(),e=new Date().getTime(),f=0;const g=withConformsTo("handleChange",[],b=>{const g=getDocumentVisibilityState$1(),h=isDocumentHidden$1(),i=new Date().getTime();f+=1,a({visibilityState:g,hidden:h,timestamp:i,visibilityChangesCount:f,sentOnDomVisibilityEvent:b,previous:{visibilityState:c,hidden:d,timestamp:e,visibilityChangesCount:f-1}}),c=g,d=h,e=i;}),h=getPrefixedPropName$1("hidden");if(h){const a=h.replace(/[H|h]idden/,"")+"visibilitychange",c=()=>g(a);// @see {@link https://caniuse.com/?search=visibilitychange}
document.addEventListener(a,c);const d=[];if(b){LEGACY_PAGE_VISIBILITY_EVENT_NAMES.forEach(a=>{const b=()=>g(a);d.push([a,b]),window.addEventListener(a,b);});}return {destroy:()=>{document.removeEventListener(a,c),b&&d.forEach(a=>{let[b,c]=a;return window.removeEventListener(b,c)});}}}return !1});var createDocumentVisibilityChangeWatcher$1 = createDocumentVisibilityChangeWatcher;

/*
 *
 * `stopPropagation`: `@dbh/dom`.
 *
 *//**
 * An event listener that invokes `stopPropagation`.
 * @param {Object} event Either a `DOM` or synthetic event.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Event/stopPropagation}
 */const stopPropagation=a=>{a.stopPropagation();};var stopPropagation$1 = stopPropagation;

const isRetinaDisplay=()=>{if(throwInServerSideRendering("isRetinaDisplay"),!window.matchMedia)return !1;const a=window.matchMedia("only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)");return (null==a?void 0:a.matches)||1<window.devicePixelRatio};var isRetinaDisplay$1 = isRetinaDisplay;

/**
 * Return a partial version of `window.screen`, with the things we are
 * interested in for tracking purposes, if supported in the browser.
 * @return {Object} .
 */const getWindowScreen=()=>{var a,b;if(throwInServerSideRendering("getWindowScreen"),null!=(a=window.screen)&&a.width||null!=(b=window.screen)&&b.height){const{availHeight:a,availWidth:b,colorDepth:c,height:d,orientation:e,pixelDepth:f,width:g}=window.screen,{angle:h,type:i}=e;// This should match `windowScreenPropType`.
return {availHeight:a,availWidth:b,colorDepth:c,height:d,orientation:{angle:h,type:i},pixelDepth:f,width:g,isRetinaDisplay:isRetinaDisplay$1()}}};var getWindowScreen$1 = getWindowScreen;

/*
 *
 * `getIsDbhStaff`: `@dbh/dom`.
 *
 */const getIsDbhStaff=()=>{let a="false";// Assignment is wrapped in a `try/catch` because `localStorage` can throw for
// `getItem` and `setItem`.
try{// We purposely store and retrieve `localStorage` values as a String, in line
// with the official specifications. We also send them as String to `Google
// Analytics` below.
a="true"===window.localStorage.getItem("isDbhStaff");}catch(a){// We ignore the failure.
}return a};var getIsDbhStaff$1 = getIsDbhStaff;

/**
 * @typedef {import('./types').AnimatePromiseAndCancel} AnimatePromiseAndCancel
 *//**
 * Animates a property of an `Object` interpolating from the current value of
 * `element[prop]` to the value of `to` argument.
 * This function mutates `element`.
 * Primarely meant to be used with `HTMLElement` style properties but it can
 * be used to animate `canvas` or more.
 * @param {string} prop The `HTMLElement` property to animate @example 'scrollTop'.
 * @param {HtmlElement} element @see {@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement}.
 * @param {number} to The final value of `element[prop]`.
 * @param {Object?} options .
 * @param {number} options.duration Should be > 0.
 * @param {Function} ease Will determine the animation easing.
 * @return {AnimatePromiseAndCancel} .
 * @example
 * ```js
 *   // Scrolls window from its original position to 2000 in 250ms.
 *   animate('scrollTop', document.documentElement, 2000, { duration: 250 });
 * ```
 */const animate=withConformsTo("animate",[],(a,b,c,d)=>{throwInServerSideRendering("animate");let e=!1;const f=new Promise(f=>{const g=b[a];let h=null;const{ease:i,duration:j}={...DEFAULT_ANIMATE_OPTIONS,...d},k=d=>{var l=Math.min;e&&f({cancelled:!0,duration:d-h}),null===h&&(h=d);// `time` is in range `[0; 1]`, when `time >= 1 the
// animation is completed.
const m=l(1,(d-h)/j);// eslint-disable-next-line no-param-reassign
b[a]=i(m)*(c-g)+g,1<=m?f({cancelled:!1,duration:d-h}):window.requestAnimationFrame(k);};g===c?f(DEFAULT_ANIMATE_PAYLOAD):window.requestAnimationFrame(k);});return f.catch(a=>{Sentry.captureException(a);}),[f,()=>{e=!0;}]});var animate$1 = animate;

/**
 * @typedef {import('./types').AnimatePromiseAndCancel} AnimatePromiseAndCancel
 *//**
 * Cross browser compatible animated scroll.
 * @param {HTMLElement} element @see {@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement}.
 * @param {number?} to Scroll value.
 * @param {boolean?} horizontal Should scroll horizontal otherwise vertical.
 * @param {Object?} animateOptions Will be passed to `animate()`.
 * @see {@link https://gist.github.com/andjosh/6764939}
 * @return {AnimatePromiseAndCancel} .
 */const animateScroll=function(a,b,c,d){if(void 0===c&&(c=!0),!a)return null;const e=c?"scrollLeft":"scrollTop";return animate$1(e,a,b,d)};var animateScroll$1 = animateScroll;

/*
 *
 * `easeInOutQuad`: `@dbh/dom`.
 *
 */const easeInOutQuad=a=>.5>a?2*a*a:-1+(4-2*a)*a;var easeInOutQuad$1 = easeInOutQuad;

const setWindowScrollY=a=>{isServerSideRendering()||window.scrollTo(0,a);};var setWindowScrollY$1 = setWindowScrollY;

/*
 *
 * `makeUniqueDomId`: `@dbh/dom`.
 *
 */const makeUniqueDomId=a=>"dbh__"+a;var makeUniqueDomId$1 = makeUniqueDomId;

/*
 *
 * `setStyle`: `@dbh/dom`.
 *
 */const setStyle=(a,b,c)=>{a&&(// eslint-disable-next-line no-param-reassign
a.style[b]=c);};var setStyle$1 = setStyle;

/*
 *
 * `removeStyle`: `@dbh/dom`.
 *
 */const removeStyle=(a,b)=>{a&&(// eslint-disable-next-line no-param-reassign
a.style[b]="");};var removeStyle$1 = removeStyle;

/*
 *
 * `getPositionWithBoundingClientRect`: `@dbh/dom`.
 *
 *//**
 * Calculates the position of `element` relative to the upper-left corner of
 * the document.
 * @param {Object} element .
 * @return {number} .
 */const getPositionWithBoundingClientRect=a=>{if(!a.getBoundingClientRect)return 0;const b=document.body.getBoundingClientRect(),c=a.getBoundingClientRect(),d=c.top-b.top;return d};var getPositionWithBoundingClientRect$1 = getPositionWithBoundingClientRect;

const getPercentageOfNumber=(a,b)=>{return a*b/100},computeElementScrollTop=(a,b)=>{let c=0,d=a;// We ideally want to scroll "on top" of the element, so we have to calculate
// the height too. About 'offsetHeight':
// @see {@link https://s.tk/questions/22675126/what-is-offsetheight-clientheight-scrollheight}
const e=a.offsetHeight;let f=e;const g=getPositionWithBoundingClientRect$1(a);if(g)c=g;else if(d.offsetParent){// This is a fallback.
do c+=d.offsetTop,d=d.offsetParent;while(d);// Generally the input fields have a label and the red error below, this means
// we need to scroll above more. This "correction" does not appear to be needed
// with `getBoundingClientRect`.
f+=getPercentageOfNumber(e,20);}let h=c-f;return b&&(h-=130),h};/**
 * Calculates the position of `element` relative to the upper left corner of
 * the document.
 * @param {Object} element .
 * @param {boolean?} fixedSearchBar .
 * @return {number} .
 */var computeElementScrollTop$1 = computeElementScrollTop;

const scrollIntoView=(a,b)=>{const c=computeElementScrollTop$1(a,b);setWindowScrollY$1(c);};var scrollIntoView$1 = scrollIntoView;

/**
 * Returns `true` if the given DOM `Id` is a `DBH` anchor.
 * @param {string} id .
 * @return {boolean}
 */const isDaybreakhotelsDomId=withConformsTo("isDaybreakhotelsDomId",[],a=>a.startsWith("#dbh__"));var isDaybreakhotelsDomId$1 = isDaybreakhotelsDomId;

const DEFAULT_STORAGE_OPTIONS={throwOnInvalidStorage:!1};// We cache in the client a reference to `window.localStorage`, in order to prevent
// accidental or malicious deletions from the global scope. In server side
// rendering it is `undefined`, because it is not meant to be used: in fact
// every function that uses it in server side rendering, throws.
const defaultStorage=(()=>{if(!isServerSideRendering())try{// Accessing `window.localStorage` can throw an exception a `DOMException`
// with `message`: "Access is denied for this document".
return window.localStorage}catch(a){}})();

/**
 * Clears an `Object` that implemenents the `Storage` interface.
 * @param {Object} storage Implements the `Storage` interface.
 * It defaults to `window.localStorage`.
 * @param {Object?} options The options.
 * @param {boolean} options.throwOnInvalidStorage Should it throw if the storage
 * is invalid (@example cookies disabled in the browser).
 * @return {boolean} Has clearing `storage` failed.
 * @throws If `storage` passed does not have a `clear` method.
 */const clearStorage=withConformsTo("clearStorage",[],(a,b)=>{const c=a||defaultStorage;throwInServerSideRendering("clearStorage");const{throwOnInvalidStorage:d}=b||DEFAULT_STORAGE_OPTIONS;if(d)validateFunction(c.clear,"computedStorage.clear","clearStorage");else if(!c||!c.clear)return !1;try{return c.clear(),!0}catch(a){// We ignore the failure.
}return !1});var clearStorage$1 = clearStorage;

/**
 * Get a value from an object that implements the `Storage` interface.
 * @param {string} key A storage key.
 * @param {any?} fallbackParam A fallback value returned if "no value" or
 * "value get fails".
 * @param {Object?} storage Implements the `Storage` interface.
 * It defaults to `window.localStorage` if not provided.
 * @param {Object?} options .
 * @param {boolean?} options.throwOnInvalidStorage Should it throw if the storage
 * is invalid (@example cookies disabled in the browser).
 * @return {string?} The value mapped to key or the fallback.
 * @throws If:
 * - It is called in server side rendering.
 * - The `key` is not a string or `storage` is invalid.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Storage}
 */const getStorageItem=withConformsTo("getStorageItem",[],(a,b,c,d)=>{const e="undefined"==typeof b?null:b,f=c||defaultStorage;throwInServerSideRendering("getStorageItem");const{throwOnInvalidStorage:g}=d||DEFAULT_STORAGE_OPTIONS;g&&validateFunction(f.getItem,"computedStorage.getItem","getStorageItem"),null!==e&&validateString(e,"fallback","getStorageItem");let h=null;try{h=f.getItem(a);}catch(a){// We ignore the failure.
}// Technically `Storage.getItem` api could return `undefined` or `null`,
// this check matches both.
return null==h?e:h});var getStorageItem$1 = getStorageItem;

/**
 * Removes an item from an object that implements the `Storage` interface.
 * @param {string} key A key of a value in the storage.
 * @param {Object} storage An instance of Storage.
 * @return {boolean} Has an exception been raised while removing the key.
 * @param {Object?} options .
 * @param {boolean?} options.throwOnInvalidStorage Should it throw if
 * the storage is invalid (@example cookies disabled in the browser).
 * @throws If:
 * 1 - key is not of type string.
 * 2 - if storage is not a valid storage instance.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Storage}
 */const removeStorageItem=withConformsTo("removeStorageItem",[],(a,b,c)=>{const d=b||defaultStorage;throwInServerSideRendering("removeStorageItem");const{throwOnInvalidStorage:e}=c||DEFAULT_STORAGE_OPTIONS;if(e)validateFunction(d.removeItem,"computedStorage.removeItem","removeStorageItem");else if(!d||!d.removeItem)return !1;try{return d.removeItem(a),!0}catch(a){// We ignore the failure.
}return !1});var removeStorageItem$1 = removeStorageItem;

/**
 * Sets a value mapped to a key in an object that implements the `Storage` interface.
 * @param {string} key The key of the Storage.
 * @param {string?} value The value we assign to `key`in Storage.
 * @param {string?} storage Implements the `Storage` interface.
 * It defaults to `window.localStorage` if not provided.
 * @param {Object?} options The options.
 * @param {boolean?} options.throwOnInvalidStorage Should it throw if the storage
 * is invalid (@example cookies disabled in the browser).
 * @return {boolean} `true` if `setItem` has been successfully called.
 * @throws If:
 * - It is called in SSR.
 * - The `key` is not a string.
 * - The `value` is not a string.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Storage}
 */const setStorageItem=withConformsTo("setStorageItem",[],(a,b,c,d)=>{const e=c||defaultStorage;throwInServerSideRendering("setStorageItem");const{throwOnInvalidStorage:f}=d||DEFAULT_STORAGE_OPTIONS;if((!e||!e.setItem)&&!f)return !1;try{return e.setItem(a,b),!0}catch(a){// We ignore the failure.
}return !1});var setStorageItem$1 = setStorageItem;

export { DEFAULT_ANIMATE_PAYLOAD, animateScroll$1 as animateScroll, clearStorage$1 as clearStorage, computeElementScrollTop$1 as computeElementScrollTop, createDocumentVisibilityChangeWatcher$1 as createDocumentVisibilityChangeWatcher, createDocumentVisibilityChangeWatcherCallbackParamPropType, easeInOutQuad$1 as easeInOutQuad, easeInOutSin$1 as easeInOutSin, getIsDbhStaff$1 as getIsDbhStaff, getPrefixedPropName$1 as getPrefixedPropName, getSerializedDataFromDom$1 as getSerializedDataFromDom, getStorageItem$1 as getStorageItem, getWindowScreen$1 as getWindowScreen, getWindowScrollY$1 as getWindowScrollY, injectScript$1 as injectScript, isDaybreakhotelsDomId$1 as isDaybreakhotelsDomId, isDocumentHidden$1 as isDocumentHidden, isRetinaDisplay$1 as isRetinaDisplay, makeUniqueDomId$1 as makeUniqueDomId, removeStorageItem$1 as removeStorageItem, removeStyle$1 as removeStyle, scrollIntoView$1 as scrollIntoView, setStorageItem$1 as setStorageItem, setStyle$1 as setStyle, setWindowScrollY$1 as setWindowScrollY, smoothScrollTo$1 as smoothScrollTo, smoothScrollToOptionsPropType, stopPropagation$1 as stopPropagation, windowScreenPropType };
