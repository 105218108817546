import '@dbh/search-context-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import 'prop-types';
import 'react-immutable-proptypes';
import { makeLocationSearch } from '@dbh/make-api-url';
import { isMap } from 'immutable';
import 'invariant';
import { oneLine } from 'common-tags';
import Sentry from '@dbh/sentry';

const renameKeys=withConformsTo("renameKeys",[],(a,b)=>a.mapEntries(a=>{let[c,d]=a;const e=b(c);if(isMap(d)){const a=renameKeys(d,b);return [e,a]}return [e,d]}));var renameKeys$1 = renameKeys;

const regex=/^(?:[^A-Z]+)|[A-Z](?:[^A-Z]*)+[a-z]|[A-Z]/gm,abbreviateString=withConformsTo("abbreviateString",[],(a,b,c)=>{const d=c||3;regex.lastIndex=0;const e=a.match(regex),[f]=e,g=e[e.length-1];return f!==g?e.map((a,c)=>0===c||a.length>d?a.charAt((b||1)-1):a).join(""):a.slice(0,d)});var abbreviateString$1 = abbreviateString;

const makeQueryString=withConformsTo("makeQueryString",[],(a,b)=>{// The rename could be done once at the start, but considering how small is
// the search context, we keep it here as it's more convenient for testing
// the single functions.
const c=renameKeys$1(a,abbreviateString$1),d=/dbhq=\((.+?\).*)\)/.exec(makeLocationSearch(c));// When `makeLocationSearch` is passed an empty `Map` and returns just
// `?dbhq=()`.
if(!d)return "";const[,e]=d;return b?e:e.replace(/'/g,"")});var makeQueryString$1 = makeQueryString;

/*
 *
 * Constants: `@dbh/get-ga-search-term-value-from-search-context`.
 *
 */const GA4_EVENT_VALUE_LENGTH_LIMIT=100;const VALUE_REMOVED_GLYPH="\u0394";const MODIFICATION_GLYPH="\u2180";

const createReplaceFieldValueWithSpecialChar=withConformsTo("createReplaceFieldValueWithSpecialChar",[],a=>{const b=withConformsTo("replaceFieldValueWithSpecialChar",[],b=>{const c=b.hasIn(a);let d=b;c&&(d=d.setIn(a,VALUE_REMOVED_GLYPH));const e=makeQueryString$1(d);return [d,e]});return b});var createReplaceFieldValueWithSpecialChar$1 = createReplaceFieldValueWithSpecialChar;

const deleteFromSearchContextAndMakeQueryString=withConformsTo("deleteFromSearchContextAndMakeQueryString",[],(a,b,c)=>{let d=a,e=makeQueryString$1(d),f=0;return b.some(a=>{var b;const g=c?[...c,a]:[a],h=d.getIn(g);return h&&(d=d.deleteIn(g),e=makeQueryString$1(d),f+=1),!!((null==(b=e)?void 0:b.length)<GA4_EVENT_VALUE_LENGTH_LIMIT)}),[d,e,f]});var deleteFromSearchContextAndMakeQueryString$1 = deleteFromSearchContextAndMakeQueryString;

const cutQueryStringToLimit=withConformsTo("cutQueryStringToLimit",[],a=>{const b=""+a.slice(0,GA4_EVENT_VALUE_LENGTH_LIMIT-1)+VALUE_REMOVED_GLYPH,c=Error(oneLine("Unable to abbreviate query string to less than or equal\n    `"+GA4_EVENT_VALUE_LENGTH_LIMIT+"` characters. It was cut at the end. Input: "+a));return Sentry.captureException(c),b});var cutQueryStringToLimit$1 = cutQueryStringToLimit;

const abbreviateSearchDate=withConformsTo("abbreviateSearchDate",[],a=>{let b,c=a;const d=a.get("searchDate"),e=d.substring(2);return c=a.set("searchDate",e),b=makeQueryString$1(c),b.length>=GA4_EVENT_VALUE_LENGTH_LIMIT&&(c=a.set("searchDate",e.replace(/-/gm,"")),b=makeQueryString$1(a)),[c,b]});var abbreviateSearchDate$1 = abbreviateSearchDate;

const abbreviateSearchText=withConformsTo("abbreviateSearchText",[],a=>{let b=a,c=makeQueryString$1(a);if(c.length>=GA4_EVENT_VALUE_LENGTH_LIMIT){const d=a.getIn(["location","searchText"]),e=abbreviateString$1(d);b=a.setIn(["location","searchText"],e+VALUE_REMOVED_GLYPH),c=makeQueryString$1(b);}return [b,c]});var abbreviateSearchText$1 = abbreviateSearchText;

const convertNumberToRomanUnicodeString=a=>String.fromCharCode(8544+a-1),fnName="getSearchTermWithUnicodeChars",getSearchTermWithUnicodeChars=withConformsTo(fnName,[],(a,b)=>{const c=b?convertNumberToRomanUnicodeString(b):void 0,d=a.length<GA4_EVENT_VALUE_LENGTH_LIMIT;return c&&d?""+a+c:d?""+a+MODIFICATION_GLYPH:a});var getSearchTermWithUnicodeChars$1 = getSearchTermWithUnicodeChars;

const isSearchTermLessThanLimit=a=>a.length<GA4_EVENT_VALUE_LENGTH_LIMIT,testQueryStringWithoutSingleQuotes=a=>{const b=makeQueryString$1(a);return [a,b]},deleteFromSearchContextFilters=a=>b=>deleteFromSearchContextAndMakeQueryString$1(b,a,["filters"]),FUNCTIONS=[testQueryStringWithoutSingleQuotes,createReplaceFieldValueWithSpecialChar$1(["location","placeId"]),abbreviateSearchText$1,createReplaceFieldValueWithSpecialChar$1(["location","searchText"]),abbreviateSearchDate$1,deleteFromSearchContextFilters(["starsMin","starsMax","priceMax"]),createReplaceFieldValueWithSpecialChar$1(["searchDate"]),a=>deleteFromSearchContextAndMakeQueryString$1(a,["searchDate"]),createReplaceFieldValueWithSpecialChar$1(["order"]),deleteFromSearchContextFilters(["priceMin","checkIn","checkOut","distance"]),createReplaceFieldValueWithSpecialChar$1(["filters","idServices"]),deleteFromSearchContextFilters(["idServices"])],getGaSearchTermValueFromSearchContext=withConformsTo("getGaSearchTermValueFromSearchContext",[],a=>{let b=a,c=makeQueryString$1(b,!0),d=0;if(!isSearchTermLessThanLimit(c)){for(const a of FUNCTIONS){let e;if([b,c,e]=a(b),e&&(d+=e),isSearchTermLessThanLimit(c))break}if(!isSearchTermLessThanLimit(c))return cutQueryStringToLimit$1(c)}return getSearchTermWithUnicodeChars$1(c,d)});var getGaSearchTermValueFromSearchContext$1 = getGaSearchTermValueFromSearchContext;

/**
 * If the given `Google` `placeId` is greater than the `GA4` limit for event
 * parameter values, it returns it abbreviated.
 * @param {string} placeId .
 * @return {string} .
 */const abbreviatePlaceId=withConformsTo("abbreviatePlaceId",[],a=>a.length<=GA4_EVENT_VALUE_LENGTH_LIMIT?a:""+a.substr(0,GA4_EVENT_VALUE_LENGTH_LIMIT-1)+MODIFICATION_GLYPH);var abbreviatePlaceId$1 = abbreviatePlaceId;

export { abbreviatePlaceId$1 as abbreviatePlaceId, getGaSearchTermValueFromSearchContext$1 as getGaSearchTermValueFromSearchContext };
