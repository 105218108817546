import { createSimpleSelector, useMakeSelect } from '@dbh/reselect-extra';
import { createSelector } from 'reselect';
import { selectDataFromSsr, makeSelectHttpRequestToServerSideRendering } from '@dbh/ssr-data-redux';
import Cookie from 'universal-cookie';
import { isServerSideRendering } from '@dbh/environment';
import { COOKIE_KEYS } from '@dbh/cookie-keys';
import { getImmutableCookie } from '@dbh/cookies';
import _once from 'lodash/once';
import PropTypes from 'prop-types';
import { createRegexToGetCanonicalDbhSubdomainFromHost } from '@dbh/domain-utils';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import 'invariant';
import { FE_API_IDS_PATHNAMES } from '@dbh/api-constants';
import { Map } from 'immutable';
import { isCountryWithForcedEnglishLocale, COUNTRIES_WITH_FORCED_ENGLISH_LOCALE } from '@dbh/admindev-constants';
import { makeSelectLocale, makeSelectCountry } from '@dbh/routing-redux';
import makeApiUrl from '@dbh/make-api-url';

/*
 *
 * Constants: `@dbh/configuration-redux`.
 *
 */const REDUX_AND_SAGA_KEY="configuration";

const selectConfiguration=a=>a.get(REDUX_AND_SAGA_KEY);var selectConfiguration$1 = selectConfiguration;

const makeSelectApiIdsToVersions=()=>createSimpleSelector(selectConfiguration$1,["apiIdsToVersions"]);var makeSelectApiIdsToVersions$1 = makeSelectApiIdsToVersions;

const makeSelectApiIdVersion=a=>createSelector(makeSelectApiIdsToVersions$1(),b=>b&&(b.get(a)||b.get("default")));var makeSelectApiIdVersion$1 = makeSelectApiIdVersion;

const handler$1=a=>{const b=getImmutableCookie(a,COOKIE_KEYS.FRONT_END_API_ENV);return null==b?void 0:b.get("value")},makeSelectFrontEndApiEnvCookieValue=()=>{const a=isServerSideRendering(),b=a?void 0:new Cookie;return a?// Avoid using `makeSelectSsrCookies` because this selector is called before
// the `universal-cookie` reducer obtain the cookies from the server.
createSelector(selectDataFromSsr,a=>a.get("frontEndApiEnvCookieValue")):()=>handler$1(b)};var makeSelectFrontEndApiEnvCookieValue$1 = makeSelectFrontEndApiEnvCookieValue;

const testSitesEnvs=["test","test-billygates","test-abramov","test-montalcini","test-perlasca","test-darwin","test-turing","test-lovelace","test-davinci","test-hopper","test-giulia","test-dante","test-production","test-production-archimedes","test-production-newton","test-production-ritchie","test-production-crockford","test-production-salk","test-production-edwardjenner","test-production-isabelmorgan","test-production-cicero","test-production-stelvio","test-production-tonale","dev1","dev2","dev3","dev4","dev5"],createRegexToGetCanonicalDbhSubdomainFromHostOnce=_once(()=>createRegexToGetCanonicalDbhSubdomainFromHost(testSitesEnvs)),getApiEnvFromHostname=withConformsTo("getApiEnvFromHostname",["hostname",PropTypes.string.isRequired],a=>{const b=a.match(createRegexToGetCanonicalDbhSubdomainFromHostOnce());if(!b)throw new Error("Cannot recognize the env of this hostname: `"+a+"`");const[,c]=b;let d=testSitesEnvs.includes(c);if(d||["www","www2"].includes(c))return c;if("dev"===c)return "test";const e=a.match(/\.(.+?)\.daybreakhotels\.\w+$/);if(!e)// Production daybreak hero.
return "www";const[,f]=e;return "dev"===f?"test":(d=testSitesEnvs.includes(f),d?f:"test")});/**
 * Computes and returns the `env` for the given hostname. Throws if the hostname
 * is not recognized as daybreakhotels, or invalid.
 * @param {string} hostname .
 * @return {string} .
 */var getApiEnvFromHostname$1 = getApiEnvFromHostname;

/**
 * Returns a selector that returns the current `env` of the front end `APIs`.
 * @return {(string[])?} .
 */const makeSelectFrontEndApiEnv=()=>createSelector(makeSelectHttpRequestToServerSideRendering(),makeSelectFrontEndApiEnvCookieValue$1(),(a,b)=>{if(b)// If the cookie is set, it overwrites everything.
return [b,b];const c=a.get("hostname");return [getApiEnvFromHostname$1(c),b]});var makeSelectFrontEndApiEnv$1 = makeSelectFrontEndApiEnv;

const handler=a=>{const b=getImmutableCookie(a,COOKIE_KEYS.FRONT_END_API_DOMAIN);return null==b?void 0:b.get("value")},makeSelectFrontEndApiDomainCookieValue=()=>{const a=isServerSideRendering(),b=a?void 0:new Cookie;return a?// Avoid using `makeSelectSsrCookies` because this selector is called before
// the `universal-cookie` reducer obtain the cookies from the server.
createSelector(selectDataFromSsr,a=>a.get("frontEndApiDomainCookieValue")):()=>handler(b)};var makeSelectFrontEndApiDomainCookieValue$1 = makeSelectFrontEndApiDomainCookieValue;

/**
 * Returns a selector that returns the `URL` of an `API`.
 * @param {string} API_ID The front end identifier of the `API`.
 * @param {Object?} urlOptionsParam The options used to build the `URL`.
 * @param {boolean} forceProductionApi Should we use `api.daybreakhotels.com`.
 * Instead of `apidev.daybreakhotels.com`.
 * @return {string} .
 * @TODO `jest` test.
 */const makeSelectApiUrl=withConformsTo("makeSelectApiUrl",[],(a,b,c)=>{const d=b||{};return createSelector(makeSelectLocale(),makeSelectCountry(),makeSelectApiIdVersion$1(a),makeSelectFrontEndApiEnv$1(),makeSelectFrontEndApiDomainCookieValue$1(),(b,e,f,g,h)=>{const{routeParameters:i,queryParameters:j}=d;let k=Map({locale:b,country:e,apiVersion:f}).merge(i);const l=k.get("country");// Some countries have "forced" en-GB locale (like `AR`).
// But we want to use the "real" locale for their generated `API` `URLs`
// (@example `ar-AE` for `AE`), otherwise the `API` will either crash or
// return an empty result.
if(isCountryWithForcedEnglishLocale(l)){const a=COUNTRIES_WITH_FORCED_ENGLISH_LOCALE[l].realLocale;k=k.set("locale",a);}const[m]=g,n={queryParameters:j,routeParameters:k,encodeQueryWithRison:!1,frontEndApiEnv:c?"www":m,forcedFrontEndApiDomain:h};return makeApiUrl(a,n)})});var makeSelectApiUrl$1 = makeSelectApiUrl;

const makeFrontEndApiUrl=withConformsTo("makeFrontEndApiUrl",[],a=>{let{apiId:b,env:c,queryParameters:d}=a;const e=FE_API_IDS_PATHNAMES[b];const f=new URL("https://example.com");return f.pathname="/api/"+e,f.hostname=c+".daybreakhotels."+"com",Object.entries(d||{}).map(a=>{let[b,c]=a;return f.searchParams.append(b,c)}),f.toString()});var makeFrontEndApiUrl$1 = makeFrontEndApiUrl;

const makeSelectFrontEndApiUrl=withConformsTo("makeSelectFrontEndApiUrl",[],(a,b)=>{const{queryParameters:c}=b||{};return createSelector(makeSelectFrontEndApiEnv$1(),b=>{let[d,e]=b;const f=d||e,g=makeFrontEndApiUrl$1({apiId:a,env:f,queryParameters:c});return [g,f,e]})});/**
 * Returns a selector that returns the `URL` of a front end `API`.
 * @param {string} FRONT_END_API_ID .
 * @param {Object?} options .
 * @param {Object?} options.queryParameters .
 * @return {string[]} .
 * @see {@link https://test.daybreakhotels.com/api/map-location-data}
 * @see {@link https://www.daybreakhotels.com/api/map-location-data}
 */var makeSelectFrontEndApiUrl$1 = makeSelectFrontEndApiUrl;

/**
 * Returns a selector that returns `true` if the front end is currently calling
 * production `APIs`.
 * @see {@link https://api.daybreakhotels.com/v1.2/IT/it-IT/webcontrols?url=/IT/it-IT}
 * @see {@link https://test-production.daybreakhotels.com/api/be-api-cache/v1.2/IT/it-IT/webcontrols?url=/IT/it-IT}
 * @see {@link https://test-production-isabelmorgan.daybreakhotels.com/api/be-api-cache/v1.2/IT/it-IT/webcontrols?url=/IT/it-IT}
 * @see {@link https://www.daybreakhotels.com/api/be-api-cache/v1.2/IT/it-IT/webcontrols?url=/IT/it-IT}
 * @return {boolean} .
 */const makeSelectIsFrontEndCallingProductionApis=()=>createSelector(makeSelectFrontEndApiEnv$1(),makeSelectFrontEndApiDomainCookieValue$1(),(a,b)=>{if(b)return b.startsWith("www.")||/^test-production-*/.test(b)||b.startsWith("api-gcp-migration.");const[c]=a,d="www"===c||/^test-production-*/.test(c);return d});var makeSelectIsFrontEndCallingProductionApis$1 = makeSelectIsFrontEndCallingProductionApis;

/**
 * Returns a selector that returns `true`, if the developer or tester has forced
 * the front end (using the panel in the development bar), to call production
 * `APIs` in a build that has `IS_PRODUCTION_WWW: false`, or in `yarn start`.
 * @return {boolean} .
 */const makeSelectIsForcedWWWEnvInNonWWWBuild=()=>()=>!1;var makeSelectIsForcedWWWEnvInNonWWWBuild$1 = makeSelectIsForcedWWWEnvInNonWWWBuild;

const useFrontEndApiEnvValue=()=>{const a=useMakeSelect(makeSelectFrontEndApiEnv$1),[b]=a;return b};var useFrontEndApiEnvValue$1 = useFrontEndApiEnvValue;

const useFrontEndApiDomainCookieValue=()=>{const a=useMakeSelect(makeSelectFrontEndApiDomainCookieValue$1);return a};var useFrontEndApiDomainCookieValue$1 = useFrontEndApiDomainCookieValue;

/*
 *
 * Package: `@dbh/configuration-redux`.
 *
 */const importConfigurationReducer=import('./reducer-b45d4d88.js'/* webpackChunkName: 'dbh.configuration-redux.reducer' */);

export { REDUX_AND_SAGA_KEY as R, useFrontEndApiDomainCookieValue$1 as a, makeSelectApiUrl$1 as b, makeSelectApiIdVersion$1 as c, makeSelectFrontEndApiEnv$1 as d, makeSelectFrontEndApiUrl$1 as e, makeSelectFrontEndApiEnvCookieValue$1 as f, makeSelectFrontEndApiDomainCookieValue$1 as g, makeSelectIsForcedWWWEnvInNonWWWBuild$1 as h, importConfigurationReducer as i, makeSelectIsFrontEndCallingProductionApis$1 as j, makeFrontEndApiUrl$1 as m, selectConfiguration$1 as s, useFrontEndApiEnvValue$1 as u };
