import 'prop-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import Cookie from 'universal-cookie';
import { COOKIE_KEYS } from '@dbh/cookie-keys';
import '@dbh/cookies';
import _noop from 'lodash/noop';

/*
 *
 * Constants: `@dbh/cookie-consent-utils`.
 *
 */// @see {@link https://app-eu.onetrust.com/cookies/categorizations?tab=Categories}
const CONSENT_GROUPS={// Necessary cookies are needed for essential functionalities like login or
// session management. They cannot be disabled.
NECESSARY:"C0001",// Performance cookies are used to measure performance (i.e. analytics).
PERFORMANCE:"C0002",// Functional cookies are used for added functionality/customization that is
// not strictly necessary (i.e. saving theme preferences)
FUNCTIONAL:"C0003",// Targeting cookies are used to track the user behaviour, they include advertising
// and remarketing cookies.
TARGETING:"C0004",// Social media cookies are cookies set by social media integrations to allow the
// user to share some content, they also track the behaviour across websites that
// include the same integration.
SOCIAL:"C0005"};const VALID_CONSENT_GROUPS=Object.values(CONSENT_GROUPS);const DEFAULT_ACTIVE_CONSENT_GROUPS=[CONSENT_GROUPS.NECESSARY];const ONE_TRUST_SCRIPT_DATA_DOMAIN_SCRIPT="018e28bb-3f48-77ef-9635-3da24e699869";const ONE_TRUST_SCRIPT_SRC="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";// @see {@link https://developers.google.com/tag-platform/security/guides/consent?consentmode=advanced#default-consent}
const DEFAULT_CONSENT_OPTIONS={ad_storage:"denied",analytics_storage:"denied",ad_personalization:"denied",ad_user_data:"denied"};

/**
 * Returns the current active consent groups extracting them from the value
 * of the `One Trust` cookie.
 * @see `@dbh/cookie-consent-utils#CONSENT_GROUPS`.
 * @param {string?} consentCookieValue The string value of the `One Trust` cookie.
 * @return {string[]} The codes for the active consent groups.
 */const getActiveConsentGroupsFromCookieValue=withConformsTo("getActiveConsentGroupsFromCookieValue",[],a=>{if(!a)return DEFAULT_ACTIVE_CONSENT_GROUPS;// @example `version=6.32.0&isIABGlobal=false&hosts=&landingPath=NotLandingPage&groups=C0001:1,C0002:0,C0003:0,C0004:0&geolocation=IT;62&AwaitingReconsent=false`
const b=new URLSearchParams(a),c=b.get("groups");if(!c)return DEFAULT_ACTIVE_CONSENT_GROUPS;// @example `C0001:1,C0002:0,C0003:0,C0004:0`
const d=c.split(",").map(a=>a.split(":")),e=d.filter(a=>{let[b,c]=a;return VALID_CONSENT_GROUPS.includes(b)&&"1"===c}).map(a=>{let[b]=a;return b});return e});var getActiveConsentGroupsFromCookieValue$1 = getActiveConsentGroupsFromCookieValue;

/**
 * Returns the current active consent groups by reading the value of the
 * `One Trust` cookie.
 * @see `@dbh/cookie-consent-utils#CONSENT_GROUPS`.
 * @param {Object?} cookies .
 * @return {string[]} The active consent groups.
 */const getActiveConsentGroups=withConformsTo("getActiveConsentGroups",[],a=>{const b=a||new Cookie,c=b.get(COOKIE_KEYS.ONE_TRUST_CONSENT);// It doesn't make sense to use `getImmutableCookie` because this cookie
// is managed by a third party.
return getActiveConsentGroupsFromCookieValue$1(c)});var getActiveConsentGroups$1 = getActiveConsentGroups;

/**
 * Returns whether the analytics storage consent has been given.
 * @param {string[]} activeConsentGroups The active consent groups.
 * @return {boolean} Whether the analytics storage consent has been given.
 */const getIsAnalyticsStorageConsentGiven=withConformsTo("getIsAnalyticsStorageConsentGiven",[],a=>a.includes(CONSENT_GROUPS.PERFORMANCE));var getIsAnalyticsStorageConsentGiven$1 = getIsAnalyticsStorageConsentGiven;

const consentGroupPropType=_noop;

export { CONSENT_GROUPS, DEFAULT_ACTIVE_CONSENT_GROUPS, DEFAULT_CONSENT_OPTIONS, ONE_TRUST_SCRIPT_DATA_DOMAIN_SCRIPT, ONE_TRUST_SCRIPT_SRC, VALID_CONSENT_GROUPS, consentGroupPropType, getActiveConsentGroups$1 as getActiveConsentGroups, getActiveConsentGroupsFromCookieValue$1 as getActiveConsentGroupsFromCookieValue, getIsAnalyticsStorageConsentGiven$1 as getIsAnalyticsStorageConsentGiven };
