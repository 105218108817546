import { fromJS, Map } from 'immutable';
import { PAGE_TYPES } from '@dbh/admindev-constants';
import { LOADING_STATUS } from '@dbh/generic-redux-constants';
import 'prop-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import _noop from 'lodash/noop';
import 'react-immutable-proptypes';
import '@dbh/generic-types';
import { createSimpleSelector } from '@dbh/reselect-extra';
import { createSelector } from 'reselect';
import { isDBHTestDomainUrl } from '@dbh/domain-utils';
import { selectLocationOriginFromServer } from '@dbh/ssr-data-redux';

const DAYBREAK_HERO_DATA_LOADED="@dbh/daybreak-hero-redux/DAYBREAK_HERO_DATA_LOADED";const TRACKING_CUSTOMIZATIONS_DEFAULTS_SET="@dbh/daybreak-hero-redux/TRACKING_CUSTOMIZATIONS_DEFAULTS_SET";const REDUX_AND_SAGA_KEY="daybreakHero";const PIXELS={ADWORDS:"adwords",AWIN:"awin",BING:"bing",CHAT_WIDGET:"chatWidget",CRITEO:"criteo",GOOGLE_ANALYTICS:"googleAnalytics",GOOGLE_ENHANCED_COMMERCE:"googleEnhancedCommerce",HOTJAR:"hotjar",MICROSOFT_CLARITY:"microsoftClarity"};

const{ADWORDS,AWIN,BING,CHAT_WIDGET,CRITEO,GOOGLE_ANALYTICS,GOOGLE_ENHANCED_COMMERCE,HOTJAR,MICROSOFT_CLARITY}=PIXELS;// @see {@link https://apidev.daybreakhotels.com/v1.2/IT/it-IT/daybreakheroinfo}
// In the headers you can try adding: `dbh-partner-id: 39`, `dbh-partner-site: testdevelop`.
const initialState=fromJS({daybreakHeroData:{customizations:{tracking:{[ADWORDS]:!1,[AWIN]:!1,[CHAT_WIDGET]:!1,[CRITEO]:!1,[HOTJAR]:!1,[MICROSOFT_CLARITY]:!1,[BING]:!0,[GOOGLE_ANALYTICS]:!0,[GOOGLE_ENHANCED_COMMERCE]:!0},[PAGE_TYPES.HdpPage]:{showHotelTitleAndAddress:!0,showHotelPhotosSlider:!0,showHotelMap:!0,showHotelServices:!0,showHotelDescription:!0,showHotelRatings:!0,showHotelPackages:!0,showPackagesAnchor:!0},[PAGE_TYPES.CancelReservationPage]:{showHotelInfo:!0,showCancellationReason:!0},[PAGE_TYPES.ConfirmReservationPage]:{showHotelInfo:!0},[PAGE_TYPES.ViewReservationPage]:{showHotelInfo:!0},[PAGE_TYPES.ViewFirstReservationPage]:{showHotelInfo:!0},[PAGE_TYPES.BookingFormPage]:{showNewsletterCheckbox:!0,showDiscountCode:!0}}},loadStatus:LOADING_STATUS.NONE});const daybreakHero=function(a,b){switch(void 0===a&&(a=initialState),b.type){case DAYBREAK_HERO_DATA_LOADED:{const{payload:{daybreakHeroData:c}}=b;return a.merge({daybreakHeroData:c,loadStatus:LOADING_STATUS.LOADED})}case TRACKING_CUSTOMIZATIONS_DEFAULTS_SET:{const{payload:{trackingCustomizationsDefaults:c}}=b;return a.updateIn(["daybreakHeroData","customizations","tracking"],a=>a.merge(c))}default:return a}};var daybreakHero$1 = daybreakHero;

const daybreakHeroDataShape={};const daybreakHeroDataImmutablePropType=_noop;

/**
 * Dispatched when the daybreak hero data is loaded.
 * @param {Immutable.Map} daybreakHeroData .
 * @return {Object} .
 */const daybreakHeroLoaded=withConformsTo("daybreakHeroLoaded",[],a=>({type:DAYBREAK_HERO_DATA_LOADED,payload:{daybreakHeroData:a}}));const trackingCustomizationsDefaultsSet=withConformsTo("daybreakHeroLoaded",[],a=>({type:TRACKING_CUSTOMIZATIONS_DEFAULTS_SET,payload:{trackingCustomizationsDefaults:Map(a)}}));

const selectStateDomain=a=>a.get(REDUX_AND_SAGA_KEY);var selectStateDomain$1 = selectStateDomain;

/**
 * Returns a selector that returns the daybreak hero data.
 * @return {Immutable.Map} .
 */const makeSelectDaybreakHeroData=()=>createSimpleSelector(selectStateDomain$1,["daybreakHeroData"]);var makeSelectDaybreakHeroData$1 = makeSelectDaybreakHeroData;

/**
 * Returns a selector that returns `true` if the current site is daybreak hero.
 * @see {@link https://testdevelop.daybreakhotels.localhost/IT/en-GB/66/day-use-Rome/Hotel-Demo-Partner}
 * @see {@link https://testdevelop.test.daybreakhotels.localhost/IT/en-GB/66/day-use-Rome/Hotel-Demo-Partner}
 * @see {@link https://testdevelop.test-production.daybreakhotels.localhost/IT/en-GB/66/day-use-Rome/Hotel-Demo-Partner}
 * @return {boolean} .
 */const makeSelectIsDaybreakHeroSite=()=>createSelector(selectStateDomain$1,a=>a.get("loadStatus")===LOADING_STATUS.LOADED);var makeSelectIsDaybreakHeroSite$1 = makeSelectIsDaybreakHeroSite;

/**
 * Returns a selector that returns the daybreak hero site landing page `URL`.
 * @see {@link https://testdevelop.daybreakhotels.localhost/IT/it-IT/day-use-Arcinazzo-Romano/TESTDEVELOP}
 * @see {@link https://testdevelop.test.daybreakhotels.localhost/IT/it-IT/day-use-Arcinazzo-Romano/TESTDEVELOP}
 * @see {@link https://testdevelop.test-production.daybreakhotels.localhost/IT/it-IT/day-use-Arcinazzo-Romano/TESTDEVELOP}
 * @return {string?} .
 */const makeSelectDaybreakHeroLandingPageUrl=()=>createSimpleSelector(makeSelectDaybreakHeroData$1(),["landingPageUrl"]);var makeSelectDaybreakHeroLandingPageUrl$1 = makeSelectDaybreakHeroLandingPageUrl;

/**
 * Returns a selector that returns `true` if the current site is a production
 * daybreak hero site, or the `www` site.
 * @return {boolean} .
 */const makeSelectIsWwwSiteOrProductionHeroSite=()=>createSelector(selectLocationOriginFromServer,makeSelectIsDaybreakHeroSite$1(),(a,b)=>{const c=new URL(a),{hostname:d}=c,e=b&&!isDBHTestDomainUrl(c);return "www.daybreakhotels.com"===d||e});var makeSelectIsWwwSiteOrProductionHeroSite$1 = makeSelectIsWwwSiteOrProductionHeroSite;

export { DAYBREAK_HERO_DATA_LOADED, PIXELS, REDUX_AND_SAGA_KEY, TRACKING_CUSTOMIZATIONS_DEFAULTS_SET, daybreakHeroDataImmutablePropType, daybreakHeroDataShape, daybreakHeroLoaded, daybreakHero$1 as daybreakHeroReducer, makeSelectDaybreakHeroData$1 as makeSelectDaybreakHeroData, makeSelectDaybreakHeroLandingPageUrl$1 as makeSelectDaybreakHeroLandingPageUrl, makeSelectIsDaybreakHeroSite$1 as makeSelectIsDaybreakHeroSite, makeSelectIsWwwSiteOrProductionHeroSite$1 as makeSelectIsWwwSiteOrProductionHeroSite, trackingCustomizationsDefaultsSet };
