import 'prop-types';
import { select, call } from 'redux-saga/effects';
import '@dbh/generic-types';
import { API_IDS_TO_VERSIONS, API_IDS, HTTP_STATUS_CODES } from '@dbh/api-constants';
import '@dbh/request-types';
import { isRelativeUrl, getUrlPartsFromRelativeUrl } from '@dbh/urls';
import { API_FIELDS_FILTER, CircularRedirectDetectedError, RedirectLoopDetectedError, DEFAULT_MAXIMUM_REDIRECTS_TO_FOLLOW } from '@dbh/circular-webpages-redirect-checker';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import { Map } from 'immutable';
import { makeSelectApiUrl } from '@dbh/configuration-redux';
import { request } from '@dbh/request-queue-redux';

const{webpages:WEBPAGES_API_ID}=API_IDS,API_VERSION=API_IDS_TO_VERSIONS[WEBPAGES_API_ID]||API_IDS_TO_VERSIONS.default,createWebpagesApiRequestBrowserOnly=withConformsTo("createWebpagesApiRequestBrowserOnly",[],function(a){let{country:b,locale:c,webpageUrl:d,requestOptions:e}=a;return function*(){const a={url:d,_makeUrlRelative:!0,_fields:API_FIELDS_FILTER.join(",")},f={encodeQueryWithRison:!1,queryParameters:Map(a),routeParameters:Map({locale:c,country:b,apiVersion:API_VERSION})},g=yield select(makeSelectApiUrl(WEBPAGES_API_ID,f)),{emptyResponse:h,apiResponse:i}=yield call(request,g,e);if(!h)// Can happen in the tests if:
// 1) `jest-fetch-mock` was enabled globally with `enableMocks` in the
// setup files of the tests.
// 2) Given 1), `fetch` was not unmocked with `fetchMock.dontMock()` in
// the test file, nor a specific mock implementation was applied with the
// available mock methods.
// @see {@link https://github.com/jefflau/jest-fetch-mock#to-setup-for-all-tests}
{const a=i["hydra:member"];return a?a[0]:void 0}}()});/**
 * Returns the `API` response of the given `webpages` `URL`.
 * @param {Object} options .
 * @param {string} options.country .
 * @param {string} options.locale .
 * @param {string} options.webpageUrl .
 * @param {Object?} options.requestOptions .
 * @return {Object?} .
 * @see {@link https://apidev.daybreakhotels.com/v1.2/IT/en-GB/webpages?url=/IT/en-GB/1/dbh-test-only-do-not-delete/lacus&_makeUrlRelative=true}
 * @see {@link https://apidev.daybreakhotels.com/v1.2/IT/en-GB/webpages?url=/IT/en-GB/1/dbh-test-only-do-not-delete/lorem&_makeUrlRelative=true}
 */var createWebpagesApiRequestBrowserOnly$1 = createWebpagesApiRequestBrowserOnly;

const{PERMANENT_REDIRECT,TEMPORARY_REDIRECT}=HTTP_STATUS_CODES,circularWebpagesRedirectCheckerBrowserOnly=withConformsTo("circularWebpagesRedirectCheckerBrowserOnly",[],function c(a,b){let{country:d,locale:e,originalUrl:f,originalUrlRedirectsTo:g,requestOptions:h,redirects:i=[]}=a;return function*(){const a=!isRelativeUrl(g);if(a)return !1;// Not using `limit=DEFAULT_MAXIMUM_REDIRECTS_TO_FOLLOW` because of a current
// limitation of `withConformsTo`.
const j="undefined"==typeof b?DEFAULT_MAXIMUM_REDIRECTS_TO_FOLLOW:b,{pathname:k}=getUrlPartsFromRelativeUrl(f),{pathname:l}=getUrlPartsFromRelativeUrl(g),m=[...i,k],n=m.includes(l);if(n)throw new CircularRedirectDetectedError([...m,l]);const o=yield call(createWebpagesApiRequestBrowserOnly$1,{country:d,locale:e,webpageUrl:l,requestOptions:h});if(o){const{statusCode:a,redirectTo:b}=o;if(1===j)throw new RedirectLoopDetectedError([...m,l,b]);[PERMANENT_REDIRECT,TEMPORARY_REDIRECT].includes(a)&&(yield call(c,{country:d,locale:e,originalUrl:l,originalUrlRedirectsTo:b,redirects:m,requestOptions:h},j-1));}return !1}()});/**
 * Returns `false` if the `webpages` `API` doesn't have circular redirects or
 * redirect loops (`admindev` configuration) for the given `URL`, otherwise throws.
 * @param {Object} options .
 * @param {string} options.country .
 * @param {string} options.locale .
 * @param {string} options.originalUrl .
 * @param {string} options.originalUrlRedirectsTo .
 * @param {Array?} options.redirects .
 * @param {number} limit Maximum number of `webpages` `API` calls to make while
 * following redirect loops.
 * @return {boolean?} False if the latest `API` response has no circular or
 * looped redirects.
 * @throws {CircularRedirectDetectedError} If a circular redirect is detected.
 * @throws {RedirectLoopDetectedError} If a loop of redirects is detected.
 */var circularWebpagesRedirectCheckerBrowserOnly$1 = circularWebpagesRedirectCheckerBrowserOnly;

export { circularWebpagesRedirectCheckerBrowserOnly$1 as circularWebpagesRedirectCheckerBrowserOnly };
