import { createSimpleSelector } from '@dbh/reselect-extra';
import { createSelector } from 'reselect';
import { makeSelectCountry, makeSelectLocale } from '@dbh/routing-redux';
import { Map, List } from 'immutable';
import { keyIn, memoize } from '@dbh/lodash-extra';
import 'react-immutable-proptypes';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';

const REDUX_AND_SAGA_KEY="countries";const LOAD_COUNTRIES_INVOKED="@dbh/countries-redux/LOAD_COUNTRIES_INVOKED";const LOAD_COUNTRIES_SUCCESS="@dbh/countries-redux/LOAD_COUNTRIES_SUCCESS";const LOAD_COUNTRIES_ERROR="@dbh/countries-redux/LOAD_COUNTRIES_ERROR";

/**
 * Load the countries, this event starts the request saga.
 * @param {string} locale The locale.
 * @return {Object} .
 */const loadCountriesInvoked=a=>({type:LOAD_COUNTRIES_INVOKED,locale:a});/**
 * Dispatched when the countries are loaded by the request saga.
 * @param {Object} countries The countries data.
 * @param {Immutable.Map} countries.requestAttributes .
 * @param {Object[]} countries.countries .
 * @param {string} countries.locale .
 * @param {string} countries.country .
 * @return {Object} .
 */const countriesLoaded=a=>{let{requestAttributes:b,countries:c,locale:d,country:e}=a;return {type:LOAD_COUNTRIES_SUCCESS,payload:{requestAttributes:b,countries:c,locale:d,country:e}}};/**
 * Dispatched when loading the countries fails.
 * @param {Error} error .
 * @return {Object} .
 */const countriesLoadingError=a=>({type:LOAD_COUNTRIES_ERROR,payload:{error:a},error:!0});

/**
 * Direct selector to the `countries` state domain.
 * @param {Immutable.Map} state .
 * @return {Immutable.Map} .
 */const selectCountries=a=>a.get(REDUX_AND_SAGA_KEY);var selectCountries$1 = selectCountries;

/**
 * @typedef {import('../types').Country} Country
 *//**
 * Returns a selector that returns the data for all countries, from the `/countries`
 * `API`.
 * @see {@link https://apidev.daybreakhotels.com/v1.2/IT/en-GB/countries?webEnabled=true}
 * @return {Immutable.Map?} .
 */const makeSelectCountriesAll=()=>createSimpleSelector(selectCountries$1,["countries"]);var makeSelectCountriesAll$1 = makeSelectCountriesAll;

/**
 * Returns a selector that returns `true` if any country is loaded.
 * @return {boolean} .
 */const makeSelectCountriesLoadedAll=()=>createSimpleSelector(selectCountries$1,["countriesLoaded"]);var makeSelectCountriesLoadedAll$1 = makeSelectCountriesLoadedAll;

/**
 * Returns a selector that returns `true` if the current country is loaded.
 * @return {boolean} .
 */const makeSelectCountriesLoaded=()=>createSelector(makeSelectCountry(),makeSelectLocale(),makeSelectCountriesLoadedAll$1(),(a,b,c)=>!!c.getIn([a,b]));var makeSelectCountriesLoaded$1 = makeSelectCountriesLoaded;

/**
 * @typedef {import('../types').Country} Country
 *//**
 * Returns a selector that returns the data for the current country, from the
 * `/countries` `API`.
 * @see {@link https://apidev.daybreakhotels.com/v1.2/IT/en-GB/countries?webEnabled=true}
 * @return {Immutable.List<Country>?} .
 */const makeSelectCountries=()=>createSelector(makeSelectCountry(),makeSelectLocale(),makeSelectCountriesLoaded$1(),makeSelectCountriesAll$1(),(a,b,c,d)=>c&&d.getIn([a,b]));var makeSelectCountries$1 = makeSelectCountries;

/**
 * @typedef {import('../types').Country} Country
 *//**
 * Returns a selector that returns the current country.
 * @return {Country?} .
 */const makeSelectCurrentCountry=()=>createSelector(makeSelectCountriesLoaded$1(),makeSelectCountry(),makeSelectCountries$1(),(a,b,c)=>a?c.find(a=>a.get("code")===b):null);var makeSelectCurrentCountry$1 = makeSelectCurrentCountry;

/**
 * Adds plus at the start of the number if it doesn't exist.
 * @typedef {Immutable.Map} SupportNumbers
 * @param {string} numberString @example `39 0694504041`.
 * @return {string?} @example `+39 0694504041`.
 */const ensureStartsWithPlus=a=>{if(a){const b="+"===a.charAt(0);return b?a:"+"+a}},makeSelectSupportNumbers=()=>createSelector(makeSelectCurrentCountry$1(),a=>{if(!a)return;const b=ensureStartsWithPlus(a.get("supportNumber")),c=ensureStartsWithPlus(a.get("supportWhatsApp"));return Map({supportNumber:b,supportWhatsapp:c})});/**
 * Returns a selector that returns the support number of the current country.
 * @return {SupportNumbers?} .
 */var makeSelectSupportNumbers$1 = makeSelectSupportNumbers;

/**
 * @typedef {import('../types').CurrencyData} CurrencyData
 *//**
 * Returns a selector that returns currency data of the current country.
 * @return {CurrencyData?} .
 */const makeSelectCurrencyData=()=>createSelector(makeSelectCurrentCountry$1(),a=>a&&a.filter(keyIn("currencyCode","currencySymbol","displayVATInfo")));var makeSelectCurrencyData$1 = makeSelectCurrencyData;

/**
 * Returns a selector that returns the current country name.
 * @return {string?} .
 */const makeSelectCurrentCountryName=()=>createSelector(makeSelectCurrentCountry$1(),a=>a&&a.get("name"));var makeSelectCurrentCountryName$1 = makeSelectCurrentCountryName;

/**
 * Returns a selector that returns the current country flag.
 * @return {string?} .
 */const makeSelectCurrentCountryFlag=()=>createSelector(makeSelectCurrentCountry$1(),a=>a&&a.get("imageUrl"));var makeSelectCurrentCountryFlag$1 = makeSelectCurrentCountryFlag;

/**
 * Given a list of countries, it maps the country codes to "enabled" culture
 * codes.
 * @param {Immutable.List<Immutable.Map>} countries Countries info
 * extracted from the `API` data.
 * @return {Immutable.Map<string, Immutable.List<string>>} A Mapping between
 * country codes and a list of the cultures visible on the website.
 * @throws {Error} If countries is not an `Immutable.List`.
 */const mapCulturesCodesToCountryCode=memoize(withConformsTo("mapCulturesCodesToCountryCode",[],a=>{const b=a.filter(a=>a.get("webEnabled")&&a.get("contextualShowWeb"));return b.reduce((a,b)=>{const c=b.get("code"),d=b.get("cultures"),e=d.reduce((a,b)=>{// Some cultures are deliberately hidden: for instance at the time of
// writing `cy-GB` is a culture of `GB` that has `webEnabled` set to
// `false`. The back end controls the setting of `webEnabled`.
const c=b.get("webEnabled");return c?a.push(b.get("id")):a},List()),f=e.find(a=>a.startsWith("en"));let g=e;// Add `en-GB`, if the culture `en` is not present. The emirate version
// of the website has only one culture `ar-AE`, that is a placeholder for
// the `en-GB`.
return f||"AE"===c||(g=g.push("en-GB")),a.set(c,g)},Map().asMutable()).asImmutable()}));var mapCulturesCodesToCountryCode$1 = mapCulturesCodesToCountryCode;

/**
 * Returns a selector that returns a mapping of country codes to a list of culture
 * codes that our application considers "enabled" for the above mentioned country.
 * The selector returns `undefined` if the `/countries` `API` is not loaded yet.
 * @return {Immutable.Map<string, Immutable.List<string>>?} Mapping between all
 * the `ISO3166-1` alpha 2 codes (such as `IT`, `GB`) of countries loaded in our
 * website and a list of cultures we support and consider "enabled" for that
 * country.
 * @see {@link https://en.wikipedia.org/wiki/ISO_3166-1}
 */const makeSelectMapCulturesCodesToCountryCode=()=>createSelector(makeSelectCountries$1(),a=>a?mapCulturesCodesToCountryCode$1(a):void 0);var makeSelectMapCulturesCodesToCountryCode$1 = makeSelectMapCulturesCodesToCountryCode;

/**
 * @typedef {import('@dbh/countries-types').RecensioniVerificate} RecensioniVerificate
 *//**
 * Returns a selector that returns recensioni verificate of the current country.
 * @return {RecensioniVerificate?} .
 */const makeSelectRecensioniVerificate=()=>createSelector(makeSelectCurrentCountry$1(),a=>a?a.get("recensioniVerificate"):void 0);var makeSelectRecensioniVerificate$1 = makeSelectRecensioniVerificate;

/*
 *
 * Package: `@dbh/countries-redux`.
 *
 */const importCountriesSaga=import('./index-8730d1fa.js'/* webpackChunkName: 'dbh.countries-redux.sagas' */);const importCountriesReducer=import('./reducer-14b4f13f.js'/* webpackChunkName: 'dbh.countries-redux.reducer' */);// Keep the `export *` at the bottom and the other types of exports above.

export { LOAD_COUNTRIES_ERROR, LOAD_COUNTRIES_INVOKED, LOAD_COUNTRIES_SUCCESS, REDUX_AND_SAGA_KEY, countriesLoaded, countriesLoadingError, importCountriesReducer, importCountriesSaga, loadCountriesInvoked, makeSelectCountries$1 as makeSelectCountries, makeSelectCountriesAll$1 as makeSelectCountriesAll, makeSelectCountriesLoaded$1 as makeSelectCountriesLoaded, makeSelectCountriesLoadedAll$1 as makeSelectCountriesLoadedAll, makeSelectCurrencyData$1 as makeSelectCurrencyData, makeSelectCurrentCountry$1 as makeSelectCurrentCountry, makeSelectCurrentCountryFlag$1 as makeSelectCurrentCountryFlag, makeSelectCurrentCountryName$1 as makeSelectCurrentCountryName, makeSelectMapCulturesCodesToCountryCode$1 as makeSelectMapCulturesCodesToCountryCode, makeSelectRecensioniVerificate$1 as makeSelectRecensioniVerificate, makeSelectSupportNumbers$1 as makeSelectSupportNumbers, selectCountries$1 as selectCountries };
