/*
 * Theme: `@dbh/entry-content`.
 *
 * This contains the `styled-components` theme for the `EntryContent` component.
 *
 * Remember to import this object into `./app/theme/index.js` and read the
 * comments in that file to activate it.
 */const THEME_KEY_ENTRY_CONTENT="entryContent";const modifyEntryContentTheme=a=>{let{colors:{white:b},spacing:{sp6:c,sp9:d,sp4:e,sp3:f}}=a;const g=c+" 0",h="70%",i="auto",j="375px",k="calc(100% - "+d+")";return {[THEME_KEY_ENTRY_CONTENT]:{backgroundColor:b,overlayBackgroundColor:"rgba(255,255,255,.75)",borderRadius:"2px",maxWidth:"unset",maxHeight:"100%",height:"100%",margin:"0 auto",top:0,wrapperPosition:"static",md:{wrapperPosition:"absolute",margin:g,maxHeight:k,maxWidth:j,width:h,height:i},lg:{wrapperPosition:"absolute",margin:g,maxHeight:k,maxWidth:j,width:h,height:i},xl:{wrapperPosition:"absolute",margin:g,maxHeight:k,maxWidth:j,width:h,height:i},body:{padding:""+c,alignItems:"initial"},headerWrapper:{margin:"0 "+e},header:{padding:"0 "+f}}}};

export { THEME_KEY_ENTRY_CONTENT, modifyEntryContentTheme as default };
