import _isFinite from 'lodash/isFinite';
import { finiteNumberPropType } from '@dbh/generic-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import { useIntl } from 'react-intl';
import 'prop-types';

const getUnit=withConformsTo("getUnit",[],a=>1e3<=a?"km":"m");var getUnit$1 = getUnit;

/**
 * Returns the distance value to display.
 * @param {number} value .
 * @return {number} .
 */const getDisplayedValue=withConformsTo("getDisplayedValue",[],a=>1e3<=a?a/1e3:a);var getDisplayedValue$1 = getDisplayedValue;

/**
 * Returns the maximum fraction digits for the given value.
 * @param {number} value .
 * @return {number} .
 */const getMaximumFractionDigits=withConformsTo("getMaximumFractionDigits",[],a=>1e3<a?1:0);var getMaximumFractionDigits$1 = getMaximumFractionDigits;

const useDistanceLabel=withConformsTo("useDistanceLabel",["distanceNumberValue",finiteNumberPropType],a=>{const b=useIntl();if(!_isFinite(a))return;const c=b.formatNumber(getDisplayedValue$1(a),{style:"decimal",minimumFractionDigits:0,maximumFractionDigits:getMaximumFractionDigits$1(a)}),d=getUnit$1(a);return ""+c+d});

export { useDistanceLabel };
