import PropTypes from 'prop-types';
import { isServerSideRendering } from '@dbh/environment';
import { createOnlyOnePropPassedPropType } from '@dbh/generic-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';

const REDUX_AND_SAGA_KEY="frontEndLogicRedirect";const FRONT_END_LOGIC_REDIRECT_TRIGGERED="@dbh/front-end-logic-redirect-redux/FRONT_END_LOGIC_REDIRECT_TRIGGERED";

const selectStateSlice=a=>null==a?void 0:a.get(REDUX_AND_SAGA_KEY);var selectStateSlice$1 = selectStateSlice;

const makeSelectFrontEndLogicRedirect=()=>selectStateSlice$1;var makeSelectFrontEndLogicRedirect$1 = makeSelectFrontEndLogicRedirect;

const frontEndLogicRedirectTriggered=withConformsTo("frontEndLogicRedirectTriggered",["options",PropTypes.exact({statusCode:PropTypes.number.isRequired,redirectLogic:PropTypes.string,redirectTo:isServerSideRendering()?PropTypes.string:PropTypes.string.isRequired,// We don't know if the `/webpages` API has already returned and we stored
// the routes in the reducer, when we submit a front end logic redirect
// in a saga, in server side rendering. This can lead to a redirect working
// intermittently. Therefore, we must do the "select route from state" step
// at the end of server side rendering, using `makeSelectUrlOfWebpagesRoute`.
...(isServerSideRendering()?{redirectToUrlOfWebpagesRoute:PropTypes.object,____customHelper:createOnlyOnePropPassedPropType("frontEndLogicRedirectTriggered",["redirectTo","redirectToUrlOfWebpagesRoute"])}:{})}).isRequired],a=>{let{redirectTo:b,statusCode:c,redirectLogic:d,redirectToUrlOfWebpagesRoute:e}=a;return {type:FRONT_END_LOGIC_REDIRECT_TRIGGERED,payload:{statusCode:c,redirectLogic:d,// Don't add `redirectTo: undefined`, it triggers our `withConformsTo`
// validator, sometimes without printing the error in the browser.
...(b?{redirectTo:b}:{}),// Don't add `redirectToUrlOfWebpagesRoute: undefined`, it triggers our
// `withConformsTo` validator, sometimes without printing the error in
// the browser.
...(e?{redirectToUrlOfWebpagesRoute:e}:{})}}});var frontEndLogicRedirectTriggered$1 = frontEndLogicRedirectTriggered;

/*
 *
 * Package: `@dbh/front-end-logic-redirect-redux`.
 *
 */const importFrontEndLogicRedirectBrowserOnlySagas=import('./index-8d3547ce.js'/* webpackChunkName: 'dbh.front-end-logic-redirect-redux.sagas' */);const importFrontEndLogicRedirectReducer=import('./reducer-30fc07a5.js'/* webpackChunkName: 'dbh.front-end-logic-redirect-redux.reducer' */);

export { FRONT_END_LOGIC_REDIRECT_TRIGGERED as F, REDUX_AND_SAGA_KEY as R, importFrontEndLogicRedirectReducer as a, frontEndLogicRedirectTriggered$1 as f, importFrontEndLogicRedirectBrowserOnlySagas as i, makeSelectFrontEndLogicRedirect$1 as m };
